import axios from "@/utils/axios.js";

export const selectRecruitTeacherRotationList = (data) => axios.get("/api/course/rotation/chart/selectRecruitTeacherRotationList", {
    params: data
})
export const certificateList = (data) => axios.get("/api/course/certificate/list", {
    params: data
})
export const homeRealEvents = (data) => axios.get("/api/course/information/homeRealEvents", {
    params: data
})
export const selectRecruitTeacherRealEvents = (data) => axios.get("/api/course/information/selectRecruitTeacherRealEvents", {
    params: data
})
export const certificateListAll = (data) => axios.get("/api/course/certificate/list", {
    params: data
})
export const selectListByCertId = (data) => axios.get("/api/course/certificate/selectListByCertId", {
    params: data
})


export const addUser = (data) => axios.post("/api/educenter/recruit/teacher/addUser", data)
export const updateTeacherPassword = (data) => axios.post("/api/educenter/recruit/teacher/updateTeacherPassword", data)
export const sendSmsCode = (data) => axios.get("/api/user/validate/code/sendSmsCode", {
    params: data
})
export const informationDetail = (data) => axios.get(`api/course/information/detail/${data.id}`, )
export const detailByTeacher = (data) => axios.get(`/api/course/certificate/detailByTeacher//${data.id}`, )