/* 取操作 */
const getLocalStorage = (key) => localStorage.getItem(key);
const getSessionStorage = (key) => sessionStorage.getItem(key);
/* 写操作 */
const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};
const setSessionStorage = (key, value) => {
  sessionStorage.setItem(key, value);
};
// 删除操作

const removeLocalStorage = (key) => {
  localStorage.removeItem(key)
}
export {
  getLocalStorage,
  getSessionStorage,
  setLocalStorage,
  setSessionStorage,
  removeLocalStorage
};
