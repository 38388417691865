<template>
  <div id="app" style="height: 100%">
    <div class="top-bar-box">
      <van-nav-bar v-if="isTopBarShow" @click-left="goBack" class="top-bar">
        <template #left>
          <van-icon name="arrow-left" />
        </template>
        <template #title>{{ navBarTitle }}</template>
        <template #right>
          <!-- v-show="isShare" -->
          <!-- <img class="rengonglogoCss" src="./assets/fenxiang.png" alt @click="showShare = true" /> -->
        </template>
      </van-nav-bar>
    </div>
    <router-view class="routerView" />
    <div class="bottom-bar-box" v-show="isBottomShow">
      <BottomBar :activeItem="activeItem" />
    </div>
  </div>
</template>

<script>
import { NavBar, Icon, Dialog, Button } from "vant";
import {
  getSessionStorage,
} from "@/utils/cache";
import BottomBar from "@/components/BottomBar";
export default {
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Dialog.Component.name]: Dialog.Component,
    BottomBar,
  },
  data() {
    return {
      isTopBarShow: false,
      isServeshow: false,
      isBottomShow: true,
      isShare: false,
      isApp: false,
      showShare: false,
      activeItem: {
        index: 0,
      },
    };
  },
  computed: {
    navBarTitle() {
      return this.$store.state.navBar.navBarTitle;
    },
  },
  updated() {
    console.log(window.location.pathname);
    switch (window.location.pathname) {
      case "/":
        this.activeItem.index = 0
        break;
      case "/recruitStudents":
        this.activeItem.index = 1
        break;
      case "/my":
        this.activeItem.index = 2
        break;
      default:
        break;
    }
  },
  watch: {
    $route(to, from) {
      this.isBottomShow = false;
      this.isTopBarShow = false;
      this.isShare = false;
      if (to.path == "/my" || to.path == "/recruitStudents") {
        this.isBottomShow = true;
        this.isTopBarShow = true;
      } else if (to.path == "/") {
        this.isTopBarShow = false;
        this.isBottomShow = true;
      } else {
        this.isBottomShow = false;
        this.isTopBarShow = true;
      }

      // if (to.name == "InsuranceCategoryDetail" || to.name == "FamilyBuy") {
      //   this.isShare = true;
      // }
    },
  },
  methods: {
    goBack() {
      this.$route.fullPath === "/" ? null : this.$router.back(-1);
    },
    showService() {
      this.isServeshow = true;
    },
    onShareSelect(option) {
      switch (option.name) {
        case "分享海报":
          this.$router.push("/SharePoster");
          this.showShare = false;
          break;
        case "分享链接":
          this.$router.push("/ShareLink");
          this.showShare = false;
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "./globalStyle/globalStyle.css";
#app {
  width: 100%;
  position: relative;
  overflow: hidden;
  /deep/ .van-nav-bar {
    background: linear-gradient(90deg, #ff5e9e, #ff7d89, #ff5e9e);
  }
  /deep/ .van-icon-arrow-left {
    font-size: 20px;
  }
  /deep/ [class*="van-hairline"]::after {
    border: none;
  }
  /deep/ .van-nav-bar__content {
    height: 50px;
  }
  /deep/ .van-nav-bar__title {
    color: #ffffff;
    font-size: 22px;
    font-weight: 300;
  }

  .top-bar {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    /deep/ .van-icon {
      color: #ffffff;
    }
  }

  .rengonglogoCss {
    width: 22px;
    height: 22px;
    z-index: 999;
  }
  .bottom-bar-box {
    width: 100%;
    height: 60px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .routerView {
    width: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 0px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #d2d2d2;
    }
  }

  /*vue闪烁*/
  [v-cloak] {
    display: none !important;
  }
}
</style>
