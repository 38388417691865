import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/rem.js'

import FastClick from 'fastclick';
import { Toast } from 'vant';

import 'swiper/swiper.min.css';

Vue.config.productionTip = false

Vue.use(Toast);

//去掉点击延迟300秒
FastClick.attach(document.body)

const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


