<template>
  <div class="home-box">
    <div class="home-swiper-box">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="v in rotationList" :key="v.id">
            <img :src="v.img" alt />
          </div>
        </div>
      </div>
    </div>
    <div class="home-title">
      <span>{{ homeRealEventsInfo.name }}</span>
      <van-icon name="arrow" @click="$router.push(`/moreEvent`)" />
    </div>
    <div
      style="padding: 15px 0px 5px 0px; width:100%"
      @click="$router.push(`/eventDetail/${homeRealEventsInfo.id}`)"
    >
      <img :src="homeRealEventsInfo.img" style="width:100%" alt />
    </div>
    <div class="home-title">
      <span>职业证书</span>
      <van-icon name="arrow" @click="$router.push(`/moreBook`)" />
    </div>
    <div style="padding: 15px 0px;">
      <div class="home-book-box" v-for="v in certificateList" :key="v.id"  @click="$router.push(`/bookDetail/${v.id}`)">
        <div class="left">
          <img src="../../../assets/loading.gif" :data-src="v.img" alt />
        </div>
        <div class="right">
          <p class="title">{{ v.name }}</p>
          <p class="content two-block">{{ v.synopsis }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { selectRecruitTeacherRotationList, certificateList, homeRealEvents } from "@/api/home";
import Swiper from 'swiper';
import { Icon } from "vant";
import lazyLoad from "@/utils/preLoding";
export default {
  components: {
    [Icon.name]: Icon
  },
  data() {
    return {
      rotationList: [],
      certificateList: [],
      homeRealEventsInfo: {}
    };
  },
  created() {
    document.title = "招生老师";
    this.init();
  },
  updated() {
    this.$nextTick(() => {
      let timer = setTimeout(() => {
        clearTimeout(timer);
        let imgItems = [...document.querySelectorAll("img")];
        lazyLoad(imgItems);
      }, 100);
    });
  },
  mounted() {
    new Swiper('.swiper-container', {
      loop: true,
    })
  },
  methods: {
    async init() {
      await selectRecruitTeacherRotationList().then((res) => {
        this.rotationList = res.data.data;
      });
      await homeRealEvents().then(res => {
        console.log(res, 99);
        this.homeRealEventsInfo = res.data.data
      })
      await certificateList().then(res => {
        this.certificateList = res.data.data;
      })
    },
    goSee(depIdentifying) {
      this.$router.push(`/Home/${depIdentifying}`);
    },
  },
};
</script>

<style lang="less" scoped>
.home-box {
  // width: 100%;
  height: calc(100% - 60px);
  background: white;
  padding: 0px 20px;
  .home-swiper-box {
    height: 200px;
    margin-top: 20px;
    overflow: hidden;
    width: 355px;
    img {
      width: 335px;
    }
  }
  .home-title {
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: flex-end;
    > span {
      font-family: PingFangSC-Regular, sans-serif;
      color: #203152;
    }
  }
  .home-book-box {
    height: 100px;
    display: flex;
    .left {
      width: 100px;
      height: 80px;
      > img {
        width: 100px;
        height: 80px;
      }
    }
    .right {
      width: 235px;
      height: 80px;
      padding-left: 15px;
      .title {
        font-family: PingFangSC-Regular, sans-serif;
        color: #203152;
        margin-top: 5px;
      }
      .content {
        font-size: 13px;
        color: #989898;
        margin-top: 5px;
      }
    }
  }
}
</style>
