<template>
  <div class="bottom-into">
    <van-tabbar
      v-model="activeItem.index"
      :border="false"
      active-color="#ff5e82"
    >
      <van-tabbar-item icon="home-o" to="/">首页</van-tabbar-item>
      <van-tabbar-item icon="notes-o" to="/recruitStudents">招生</van-tabbar-item>
      <van-tabbar-item icon="user-o" to="/my">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from "vant";
export default {
  props: ["activeItem"],
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  data() {
    return {
      active: 0,
    };
  },
};
</script>

<style lang="less" scoped>
.bottom-into {
  /deep/ .van-tabbar {
    width: 100%;
    height: 60px;
    border: 1px solid white;
    // .van-icon {
    //   font-size: 24px;
    // }
    // .van-tabbar-item__text {
    //   font-size: 13px;
    // }
  }
}
</style>