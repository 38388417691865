const shareMock = {
  state: () => ({ shareMockTitle: "", shareMockId: "", shareImage: "", shareUrl: "", insureShareOrder: {} }),
  mutations: {
    updateShareMock(state, value) {
      state.shareMockTitle = value.shareMockTitle;
      state.shareMockId = value.shareMockId;
      state.shareImage = value.shareImage;
      state.shareUrl = value.shareUrl;
      state.insureShareOrder = value.insureShareOrder;
    },
  },
  actions: {},
  getters: {}
}

export default shareMock