import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/System/Home'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: "/recruitStudents",
    name: 'RecruitStudents',
    component: () => import('@/views/System/RecruitStudents'),
  },
  {
    path: "/login",
    name: 'Login',
    component: () => import('@/views/System/Login'),
  },
  {
    path: "/my",
    name: 'My',
    component: () => import('@/views/System/My'),
  },
  {
    path: "/personCenter",
    name: 'PersonCenter',
    component: () => import('@/views/System/PersonCenter'),
  },
  {
    path: "/myStudent",
    name: 'MyStudent',
    component: () => import('@/views/System/MyStudent'),
  },
  {
    path: "/myMessage",
    name: 'MyMessage',
    component: () => import('@/views/System/MyMessage'),
  },
  {
    path: "/updatePassword",
    name: 'UpdatePassword',
    component: () => import('@/views/System/UpdatePassword'),
  },
  {
    path: "/moreEvent",
    name: 'MoreEvent',
    component: () => import('@/views/System/MoreEvent'),
  },
  {
    path: "/moreBook",
    name: 'MoreBook',
    component: () => import('@/views/System/MoreBook'),
  },
  {
    path: "/eventDetail/:id",
    name: 'EventDetail',
    component: () => import('@/views/System/EventDetail'),
  },
  {
    path: "/bookDetail/:id",
    name: 'BookDetail',
    component: () => import('@/views/System/BookDetail'),
  },
  {
    path: "*",
    name: '404',
    component: () => import('@/views/System/404'),
  }
]

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
})

export default router