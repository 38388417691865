const lazyLoad = (imgItems) => {
  if (IntersectionObserver) {
    let lazyImageObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry, index) => {
        let lazyImage = entry.target;
        // 如果元素可见
        if (entry.intersectionRatio > 0) {
          if (lazyImage.getAttribute("src").includes("loading")) {
            lazyImage.src = lazyImage.getAttribute("data-src");
          }
          lazyImageObserver.unobserve(lazyImage)
        }
      })
    })
    imgItems.forEach(img => {
      lazyImageObserver.observe(img);
    })
  }
}

export default lazyLoad