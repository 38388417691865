const navBar = {
  state: () => ({ navBarTitle: "", }),
  mutations: {
    updateBarText(state, value) {
      state.navBarTitle = value;
    }
  },
  actions: {},
  getters: {}
}

export default navBar