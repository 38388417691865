import http from "axios";
import router from "@/router";
import {
  Toast
} from 'vant'
import {
  getLocalStorage,
  removeLocalStorage

} from "@/utils/cache";

const axios = http.create({
  timeout: 30000,
  baseURL: process.env.NODE_ENV === "production" ?
    "https://gateway.zryyedu.com" : "https://gateway.zryyedu.com",
});

// request 拦截器
axios.interceptors.request.use(
  (config) => {
    if (getLocalStorage("token")) {
      config.headers["Authorization"] = "Bearer " + getLocalStorage("token");
    }
    return config;
  },
  (error) => {
    console.log("request:" + error);
    Promise.reject(error);
  }
);

// response 拦截器
axios.interceptors.response.use(
  (response) => {
    const data = response.data;
    if (data.code !== 200) {
      Toast({
        message: data.msg,
        icon: 'info-o',
      });
    }
    return response;
  },
  (error) => {
    alert('response:' + error);
    switch (error.response.status) {
      case 401:
        removeLocalStorage("token")
        router.push("/login")
        break;
      default:
        break;
    }
    return Promise.reject(error);
  }
);

export default axios;
